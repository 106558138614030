import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import NavigationBar from './components/NavigationBar';
import SupportChatWindow from './components/SupportChatWindow';
import VideoOfTheDayIcon from './components/VideoOfTheDayIcon';
import Booking from './pages/Booking';
import AboutUs from './pages/AboutUs';
import Landing from './pages/Landing';
import Services from './pages/Services';
import SetServices from "./pages/SetServices";
import Admin from './pages/Admin';
import Roles from "./pages/Roles";
import Videooftheday from "./pages/Videooftheday";
import Videobackground from "./pages/Videobackground";
import YouTube from "react-youtube";
import { TikTok } from "react-tiktok";
// import BackgroundVideo from "./pages/BackgroundVideo.jsx";
import PrivateRoutes from "./components/PrivateRoutes.jsx";
import backgroundVideo from "./assets/Download.mp4";
import Profile from "./pages/Profile.jsx";
// import "./app.css";
import "./style.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { useAuthContext, AuthContext } from "./context/AuthContext";


function App() {
  const { currentUser, backgroundId, role } = useContext(AuthContext);
  const opts = {
    width: '150vw',
    height: '150vh',
    fullscreen: 1,
    frameBorders: 1,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      fullscreen: 1,
      mute: 1,
      // playsinline: 0,



    },
  };

  // const _makeFullscreen = () => {
  //   if (this.props.videoOpts.fullscreen === 1) {
  //     const playerElement = document.getElementById('widget2')
  //     const requestFullScreen =
  //       playerElement.requestFullScreen ||
  //       playerElement.mozRequestFullScreen ||
  //       playerElement.webkitRequestFullScreen
  //     if (requestFullScreen) {
  //       requestFullScreen.bind(playerElement)()
  //     }
  //   }
  // }
  // const videoBackgroundStyle = {
  // const isAdminOrManager = role === "Admin" || role === "Manager";

  // }
  // const ProtectedRoute = ({ user, role, randomParam, children }) => {
  //   // get authcontext

  //   //console.log(`role is ==> ${role}`);
  //   //console.log("user is ==>", currentUser.uid);
  //   //find roles in authcontext
  //   console.log(`random param is ==> ${currentUser.displayName}`);
  //   if (role != "Admin" && role != "Manager") {
  //     console.log("role is still  ==>", role);

  //     return <Navigate to="/login" />;
  //   }

  //   return children
  // };

  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        <Route path="/">
          <Route index element={<Landing />} />
          {/* <Route
            path="supportchat"
            element={
              <ProtectedRoute user={currentUser} role={role} />              
            }
          >
              <Home />
          </Route> */}

          <Route path="login" element={currentUser ? <Landing /> : <Login />} />
          <Route path="register" element={<Register />} />
          <Route path="services" element={<Services />} />
          <Route path="booking" element={<Booking />} />
          <Route path="aboutUs" element={<AboutUs />} />

        </Route>
        <Route element={<PrivateRoutes />}>
        <Route path="/profile" element={ <Profile />} />
          <Route path="/supportchat" element={ <Home />} />
          <Route path='/SetServices' element={<SetServices />} />
          <Route path='/Roles' element={<Roles />} />
          <Route path='/Videooftheday' element={<Videooftheday />} />
          <Route path='/Videobackground' element={<Videobackground />} />
          <Route path='/Admin' element={<Admin />} />
        </Route>
      </Routes>
      <div className="overlay">
        <div class="video-container">
          {backgroundId == "" || backgroundId == undefined ?
            <video className='videoTag' autoPlay loop muted>
              <source src={backgroundVideo} type='video/mp4' />
            </video>
            : <iframe src={`https://www.youtube.com/embed/${backgroundId}?autoplay=1&rel=0\&mute=1&playsinline=1&loop=1&controls=0&showinfo=0&playlist=${backgroundId}`} rel="0"></iframe>
          }
        </div>

      </div>
      {/* <video className='videoTag' autoPlay loop muted>
        <source src={backgroundVideo} type='video/mp4' />
      </video>  */}

      {/* <BackgroundVideo />
       {/* <YouTube videoId="y4ia0Ywcl6g" opts={opts} className="youtubeContainer"/> */}


      <VideoOfTheDayIcon />
      <SupportChatWindow />
    </BrowserRouter>
  );
}

export default App;
