import * as React from 'react';

export default function Booking() {
  return (
    <div className="booking">
      <div className="services-div" id="noScroll">
        <h1>Booking</h1>
        <p style={{padding: '5%'}}>Schedule a free consult with a Trainer on Retainer now to decide if we're right for your needs.</p>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <a
            style={{ float: 'none' }}
            target="_blank"
            href="https://booking.setmore.com/scheduleappointment/d8143eea-fe7d-4c97-ae15-591260e588a5"
          >
            <img
              border="none"
              src="https://storage.googleapis.com/full-assets/setmore/images/1.0/Settings/book-now-blue.png"
              alt="Book an appointment with Dog Trainer Retainer using Setmore"
            />
          </a>
        </div>
      </div>
    </div>
  );
}