import React, { useState } from 'react';

import { styles } from '../styles';
import EmailForm from './EmailForm';
import ChatEngine from "./ChatEngine";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";

import { useContext, useEffect } from "react";

const ChatWindow = (props) => {
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { currentChat } = useContext(ChatContext);

  // set user to current user if logged in
  // also set chat to current chat if logged in
  useEffect(() => {
    console.log("Hey were in the chat window :D")
    // console.log(`And the current user is: ${currentUser}`)
    if (currentUser) {
      setUser(currentUser);
    } else {
      setUser(null);
    }

    if (currentChat) {
      setChat(currentChat);
    } else {
      setChat(null);
    }

  }, [currentUser, currentChat]);
  console.log('current user is now BLABALABLAB', user);
  console.log('current chat', chat);

  return (
    <div
      className="chatwindow transition-5"
      style={{
        ...styles.supportWindow,
        ...{
          opacity: props.visible ? '1' : '0',
          zIndex: props.visible ? '10000' : '-10000'
        },
      }}
    >
      <EmailForm
        visible={user == null}
        user={user}
        setUser={(user) => setUser(user)}
        setChat={(chat) => setChat(chat)}
      />

      <ChatEngine
        //  visible={user === null || chat === null} 
        visible={user !== null}
        user={user}
        chat={chat}
        setChat={(chat) => setChat(chat)}
      />

    </div>
  );
};

export default ChatWindow;
