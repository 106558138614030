import React, { useContext } from "react"
import '../style-components.css';
import { useNavigate, Link } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { AuthContext } from "../context/AuthContext";
import { Navbar, Container, Nav, NavDropdown, Row, Spinner } from 'react-bootstrap';
import { resolve } from "url";

function NavigationBar() {

    const { currentUser, role, setCurrenUser} = useContext(AuthContext)
    const navigate = useNavigate();

    // create signout function that calls firebase signout function anbd redirects to home page
    const logOut = () => {
        signOut(auth).then(() => {
            // Sign-out successful. show value of current user
            console.log("signout successful. current user:");
            setCurrenUser(null);
            navigate("/");
        }).catch((error) => {
            // An error happened.
            console.log("signout error");
        });
    }


    console.log(`role: ${role}`);

    return (
        <div id="navBar">
            <Navbar expand="lg">
                <Container>
                    <Link to="/">
                        <Navbar.Brand href="#home">Life Coaching for You and Your Dog</Navbar.Brand>
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="col justify-content-around me-auto">
                            <Link to="/">
                                <Nav.Link href="#link">Home</Nav.Link>
                            </Link>
                            
                            <Link to="/aboutUs">
                                <Nav.Link href="#link" to="/aboutUs">
                                    About Us
                                </Nav.Link>
                            </Link>
                            <Link to="/services">
                                <Nav.Link href="#link">Services</Nav.Link>
                            </Link>
                            {currentUser
                                ? <Link to="/logout">
                                    <Nav.Link onClick={() => logOut()}>
                                        Logout
                                    </Nav.Link>
                                </Link>
                                : <Link to="/login">
                                    <Nav.Link href="#link" to="/login">
                                        Sign Up/Login
                                    </Nav.Link>
                                </Link>
                            }

                            <Link to="/booking">
                                <Nav.Link href="#link" to="/booking">
                                    Booking
                                </Nav.Link>
                            </Link>
                           

                            {currentUser && (role === "Admin" || role === "Manager")
                                ? <NavDropdown title="Admin" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/roles">
                                        Set Roles
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/supportchat">
                                        Support chat
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/videooftheday">
                                        Set video of the day
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/videobackground">
                                        Set video background
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/setservices">
                                        Set Services
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/profile">
                                        Profile
                                    </NavDropdown.Item>
                                </NavDropdown>
                                : null}
                            {/* <Link to="/admin">
                                <Nav.Link href="#link" to="/admin">
                                    Admin
                                </Nav.Link>
                            </Link> */}


                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavigationBar