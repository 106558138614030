import * as React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import '../style-components.css';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { db } from '../firebase';
import { collection, doc, setDoc } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
function SetServices() {



    const [title1, setTitle1] = useState("");
    const [title2, setTitle2] = useState("");
    const [title3, setTitle3] = useState("");
    const [description1, setDescription1] = useState("");
    const [description2, setDescription2] = useState("");
    const [description3, setDescription3] = useState("");
    const realtimeUpdatesFromDb = onSnapshot(doc(db, "services", process.env.REACT_APP_SERVICES_DOC_ID), (doc) => {
        console.log("Current data: ", doc.data().service1);
        setTitle1(doc.data().service1);
        setTitle2(doc.data().service2);
        setTitle3(doc.data().service3);
        setDescription1(doc.data().service1Description);
        setDescription2(doc.data().service2Description);
        setDescription3(doc.data().service3Description);
    });
    async function updateServices() {

        const servicesRef = collection(db, "services");
        await setDoc(doc(servicesRef, process.env.REACT_APP_SERVICES_DOC_ID), {
            service1: document.getElementById('service1').value,
            service1Description: document.getElementById('service1Description').value,
            service2: document.getElementById('service2').value,
            service2Description: document.getElementById('service2Description').value,
            service3: document.getElementById('service3').value,
            service3Description: document.getElementById('service3Description').value,
        }).then(

        )

    }
    return (
        <div className="services">
            {/* You are here */}
            <div className="services-div">

                <h1 style={{ marginBottom: ".5em", marginTop: "1em" }}>Set Services</h1>
                <input type="text" placeholder='Initial Consultation' id='service1' style={{ width: "18em" }} defaultValue={title1} onChange={(event) => { setTitle1(event.target.value) }} />
                <textarea name="" id="service1Description" style={{ width: "18em", marginBottom: "2em" }} rows="5" placeholder='service description' defaultValue={description1} onChange={(event) => { setDescription1(event.target.value) }}></textarea>

                <input type="text" placeholder='Appointments' id='service2' style={{ width: "18em" }} defaultValue={title2} onChange={(event) => { setTitle2(event.target.value) }} />
                <textarea name="" id="service2Description" style={{ width: "18em", marginBottom: "2em" }} rows="5" placeholder='service description' defaultValue={description2} onChange={(event) => { setDescription2(event.target.value) }}></textarea>

                <input type="text" placeholder='On-demand Support' id='service3' style={{ width: "18em" }} defaultValue={title3} onChange={(event) => { setTitle3(event.target.value) }} />
                <textarea name="" id="service3Description" style={{ width: "18em", marginBottom: "2em" }} rows="5" placeholder='service description' defaultValue={description3} onChange={(event) => { setDescription3(event.target.value) }}></textarea>
                <button onClick={() => updateServices()} className='set-service-btn-mobile' style={{ marginBottom: "2em", width: "5em", height: "2em", backgroundColor: "#24a0ed", borderRadius: "5px", zIndex: "1" }}>Save</button>

            </div>
        </div>
    );
}

export default SetServices;
