import * as React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import '../style-components.css';
import { initializeApp } from "firebase/app";
import { getAuth, updateProfile, updateEmail, updatePassword } from "firebase/auth";
import { getStorage, getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { db, storage } from '../firebase';
import { collection, doc, setDoc } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Add from "../img/addAvatar.png";

function Profile() {
  //should have email, password, Avatar (photoURL) and username
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  const [username, setUsername] = useState("");
  const auth = getAuth();


  //makes changes reflect in realtime
  const realtimeUpdatesFromDb = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
    // console.log("Current data: ", doc.data().service1);
    setEmail(doc.data().email);
    
    setAvatar(doc.data().photoURL);
    setUsername(doc.data().displayName);
    //setUsername(doc.data().service1);
    // setTitle1(doc.data().service1);
    // setTitle2(doc.data().service2);
    // setTitle3(doc.data().service3);
    // setDescription1(doc.data().service1Description);
    // setDescription2(doc.data().service2Description);
    // setDescription3(doc.data().service3Description);
  });
  async function updateServices() {
    //figure out how to update image in profile after uploading image
      // const image = document.getElementById("profilePic");
      // image.src = document.getElementById('file').files[0];
    //updates the user details in firebase
    const date = new Date().getTime();
    const file = document.getElementById('file').files[0];
    const displayName = document.getElementById('displayName').value;
    const storageRef = ref(storage, `${displayName + date}`);
    await uploadBytesResumable(storageRef, file).then(() => {
      getDownloadURL(storageRef).then(async (downloadURL) => {

        await updateProfile(auth.currentUser, {
          displayName: document.getElementById('displayName').value,
          photoURL: downloadURL,

        }).then(() => {
          // Profile updated!
          // ...
        }).catch((error) => {
          // An error occurred
          // ...
        });
        await updateEmail(auth.currentUser, document.getElementById('email').value).then(() => {
          // Email updated!

          // ...
        }).catch((error) => {
          // An error occurred
          // ...
        });
        await updatePassword(auth.currentUser,document.getElementById('password').value,).then(() => {
          // Update successful.
      }).catch((error) => {
          // An error occurred
          // ...
      });
        await setDoc(doc(db, "users", currentUser.uid), {
          uid: currentUser.uid,
          displayName: document.getElementById('displayName').value,
          photoURL: downloadURL,
          email: document.getElementById('email').value,
        })

      })
    })
    // await setDoc(doc(servicesRef, process.env.REACT_APP_SERVICES_DOC_ID), {
    //     service1: document.getElementById('service1').value,
    //     service1Description: document.getElementById('service1Description').value,
    //     service2: document.getElementById('service2').value,
    //     service2Description: document.getElementById('service2Description').value,
    //     service3: document.getElementById('service3').value,
    //     service3Description: document.getElementById('service3Description').value,
    // }).then(

    // )

  }
  return (
    <div className="services">
      {/* You are here */}
      <div className="services-div">

        <h1 style={{ marginBottom: ".5em", marginTop: "1em" }}>Profile</h1>

        <input required style={{ display: "none" }} type="file" id="file" />
        <label htmlFor="file" style={{ display: "flex", flexDirection: "column", marginBottom: "1rem", cursor: "pointer" }}>
          <img src={currentUser.photoURL} className='profilePic' id="profilePic" />
          <span>Change avatar</span>
        </label>
        <input type="text" placeholder='Change Username' id='displayName' style={{ width: "18em" }} defaultValue={currentUser.displayName} onChange={(event) => { setUsername(event.target.value) }} />

        <input type="text" placeholder='Change Email' id='email' style={{ width: "18em" }} defaultValue={currentUser.email} onChange={(event) => { setEmail(event.target.value) }} />
        <input type="text" placeholder='New Password' id='password' style={{ width: "18em" }} onChange={(event) => { setPassword(event.target.value) }} />
        <button onClick={() => updateServices()} className='set-service-btn-mobile' style={{ marginBottom: "2em", width: "5em", height: "2em", backgroundColor: "#24a0ed", borderRadius: "5px", zIndex: "1" }}>Update</button>

      </div>
    </div>
  );
}

export default Profile;
