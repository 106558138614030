import React, { useState, useEffect, useContext } from "react";
import { db, supportUserID } from "../../../firebase";
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import Chat from "../../Chat";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import { styles } from "../styles";


const ChatEngine = props => {

    const [showChat, setShowChat] = useState(false);
    const { currentUser, role } = useContext(AuthContext);
    const { data, dispatch } = useContext(ChatContext);

    const handleSelect = (u) => {
        dispatch({ type: "CHANGE_USER", payload: u });
    };





    useEffect(() => {
        console.log("currentUser in chat is: ", currentUser);
        if ( currentUser ) {
            setTimeout(async () => {
                // get the support user
                const supportUserRes = await getDoc(doc(db, "users", supportUserID));
                console.log('Support user: ', supportUserRes.data())
                let combinedId = '';

                combinedId = supportUserID + currentUser.uid;

                console.log('currentUser: ', currentUser);
                console.log('combinedID: ', combinedId);

                let signinUserChatsRes = await getDoc(doc(db, "userChats", supportUserID));

                if (!signinUserChatsRes.exists()) {
                    console.log('There are no user chats for', supportUserRes.email, ', creating new chat thread');
                    //create empty user chats on firestore
                    await setDoc(doc(db, "userChats", supportUserRes.uid), {});

                    
                    //create a chat in chats collection
                    await setDoc(doc(db, "chats", combinedId), { messages: [] });

                    //create user chats
                    // one from current user to support
                    await updateDoc(doc(db, "userChats", currentUser.uid), {
                        [combinedId + ".userInfo"]: {
                            uid: supportUserID,
                            displayName: supportUserRes.data().displayName,
                            photoURL: supportUserRes.data().photoURL,
                        },
                        [combinedId + ".date"]: serverTimestamp(),
                    });

                    // another from  support to current user
                    await updateDoc(doc(db, "userChats", supportUserID), {
                        [combinedId + ".userInfo"]: {
                            uid: currentUser.uid,
                            displayName: currentUser.displayName,
                            photoURL: currentUser.photoURL,
                        },
                        [combinedId + ".date"]: serverTimestamp(),
                    });

                }
                else {
                  //  console.log('User chats exist for', props.user.email);

                    console.log('Signed in userChats: ', signinUserChatsRes.data()[combinedId]);
                 //    console.log('Combined ID: ',signinUserChatsRes.data()[combinedId].userInfo);

                    props.setChat(signinUserChatsRes.data()[combinedId]);
               

                    if (signinUserChatsRes.data()[combinedId] === undefined) {
                       
                        console.log('no chat session exists with support. add one .');

                        //create a chat in chats collection
                        await setDoc(doc(db, "chats", combinedId), { messages: [] });

                        //create user chats
                        // one from current user to support
                        await updateDoc(doc(db, "userChats", props.user.uid), {
                            [combinedId + ".userInfo"]: {
                                uid: supportUserID,
                                displayName: supportUserRes.data().displayName,
                                photoURL: supportUserRes.data().photoURL,
                            },
                            [combinedId + ".date"]: serverTimestamp(),
                        });

                        // another from  support to current user
                        await updateDoc(doc(db, "userChats", supportUserID), {
                            [combinedId + ".userInfo"]: {
                                uid: props.user.uid,
                                displayName: props.user.displayName,
                                photoURL: props.user.photoURL,
                            },
                            [combinedId + ".date"]: serverTimestamp(),
                        });

                        signinUserChatsRes = await getDoc(doc(db, "userChats", props.user.uid));

                        if (signinUserChatsRes.exists()) {
                            props.setChat(signinUserChatsRes.data()[combinedId]);
                            handleSelect(signinUserChatsRes.data()[combinedId].userInfo);

                            console.log('ChatContext data: ', data);
                        }
                    } else {
                        handleSelect(signinUserChatsRes.data()[combinedId].userInfo);
                    }
                } 
                setShowChat(true);
                console.log('showChat: ', showChat);
            }, (role == 'Admin') ? [] : 500);
        }
    }, role =='Admin' ? []: [currentUser,role,props]);

    return (
        <div
            style={{
                ...styles.chatEngineWindow,
                ...{
                    height: props.visible ? '100%' : '0px',
                    opacity: props.visible ? '1' : '0'
                }
            }}>
            {
                showChat &&
                <Chat />
            }
        </div>
    )
};

export default ChatEngine;