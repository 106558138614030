import * as React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import '../style-components.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';
import { useEffect, useState } from "react";
function Services() {
  
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [title3, setTitle3] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const realtimeUpdatesFromDb = onSnapshot(doc(db, "services", process.env.REACT_APP_SERVICES_DOC_ID), (doc) => {
    console.log("Current data: ", doc.data().service1);
    setTitle1(doc.data().service1);
    setTitle2(doc.data().service2);
    setTitle3(doc.data().service3);
    setDescription1(doc.data().service1Description);
    setDescription2(doc.data().service2Description);
    setDescription3(doc.data().service3Description);
  });
  
  return (
    <div className="services">
      {/* You are here */}
      <div className="services-div">
      <h1>Services</h1>
      <Accordion defaultActiveKey="" id="accordion">
        <Accordion.Item eventKey="0">
          {/*Initial Consultation*/}
          <Accordion.Header>{title1}</Accordion.Header>
          <Accordion.Body>
          {description1}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
           {/*Appointments*/}
          <Accordion.Header>{title2}</Accordion.Header>
          <Accordion.Body>
          {description2}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          {/*On-demand Support*/}
          <Accordion.Header>{title3}</Accordion.Header>
          <Accordion.Body>
          {description3}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
    </div>
  );
}

export default Services;
