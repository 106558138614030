import React, { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from '../firebase';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FormControl } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { TikTok } from 'react-tiktok';
import { Toaster, toast } from 'react-hot-toast';
import '../style-components.css';
import YouTube from "react-youtube";

const VideoBackground = () => {

    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [videoDisplayDate, setVideoDisplayDate] = useState(new Date().toISOString().split('T')[0]);
    const [options, setOptions] = useState([]);
    const [videoOfTheDayURL, videoBackgroundOfTheDayURL] = useState([]);
    const [showModal, setShow] = useState(false);
    const [youtubeVideoId, setyoutubeVideoId] = useState([]);
    const opts = {
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };

    const handleClose = () => setShow(false);
    
    const handleShow = () => {
        // get video url
        const videoUrl = document.getElementById('videourl').value;

        // set video url
        videoBackgroundOfTheDayURL(videoUrl);
        if(videoUrl.includes("youtube")){
            setyoutubeVideoId(videoOfTheDayURL.split("v=")[1].split("&")[0]);
        }
        setShow(true);

    }




    // change video display date
    const handleDateChange = async (e) => {
        const tmpvideoDisplayDate = e.target.value;

        // console.log(tmpvideoDisplayDate);
        setVideoDisplayDate(tmpvideoDisplayDate);

        // query db for video url by date
        const docRef = doc(db, "scheduledvideobackground", tmpvideoDisplayDate);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            const videoUrl = docSnap.data().url;
            // set video url
            const videoUrlInput = document.getElementById('videourl');
            videoUrlInput.value = videoUrl;
            //videoBackgroundOfTheDayURL(videoUrl);
            // set toasts
            toast.success(`Video background of the day found for ${tmpvideoDisplayDate}:\n\n${videoUrl}`, {
                style: {
                    border: '1px solid #713200',
                    padding: '16px',
                    color: '#713200',
                },
                iconTheme: {
                    primary: '#713200',
                    secondary: '#FFFAEE',
                },
            });
        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
            // clear video url
            const videoUrlInput = document.getElementById('videourl');
            videoUrlInput.value = "";
            videoBackgroundOfTheDayURL("");
            if(videoUrlInput.includes("youtube")){
                setyoutubeVideoId("");
            }

        }

    }


    // update video of the day
    const handleURLChange = async (e) => {
        const tempVideoOfTheDayURL = e.target.value;
        videoBackgroundOfTheDayURL(tempVideoOfTheDayURL);
        if(tempVideoOfTheDayURL.includes("youtube")){
            setyoutubeVideoId(videoOfTheDayURL.split("v=")[1].split("&")[0]);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Add a new document in collection "cities"
        await setDoc(doc(db, "scheduledvideobackground", videoDisplayDate), {
            url: videoOfTheDayURL,
            dateActive: new Date(videoDisplayDate)
        }).then(() => {
            toast.success('Video of the day updated successfully');
            setLoading(false);
        }).catch((error) => {
            toast.error('Error updating video of the day');
            setLoading(false);
        });
    }

    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.src = "https://www.tiktok.com/embed.js";
    //     script.async = true;
    //     document.body.appendChild(script);
    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);


    return (
        <div className="videooftheday">
            <Toaster />
            <div className="services-div" id="noScroll">
                <h1>Video background</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">

                        <Col>
                            <label for="videostart">Start date:</label>

                            <FormControl type="date" id="videostart" name="videostart"
                                value={videoDisplayDate}
                                min="2018-01-01" max="2099-12-31" onChange={handleDateChange} />

                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">

                        <Col>
                            <label for="videourl">Youtube Video URL (copy link from the web):</label>

                            <Form.Control id="videourl" placeholder="URL link" onChange={handleURLChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3 text-center">
                        <Col sm={{ span: 12, offset: 2 }}>
                            <Button variant="success" disabled={videoOfTheDayURL.length == 0} onClick={handleShow} className="preview-btn-mobile">
                                Preview
                            </Button>
                            <Button type="submit">Save</Button>
                            <input type="hidden" name="uid" value="" />
                            {err && <span>Something went wrong</span>}
                        </Col>
                    </Form.Group>

                </Form>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Video Background: {videoDisplayDate}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
0                           <><YouTube videoId={youtubeVideoId}  iframeClassName="youtubeIframe" opts={opts}/></>
                        {/* <TikTok url={videoOfTheDayURL} /> */}

                    </Modal.Body>
                </Modal>

            </div>
        </div>


    );

};

export default VideoBackground;