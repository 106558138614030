import React, { useState, useContext } from "react"
import { styles } from "../styles"
import { LoadingOutlined } from '@ant-design/icons'
import Avatar from '../Avatar'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db, storage, defaultAvatarURL, supportUserID } from "../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, query, where, getDocs, doc, setDoc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";


const EmailForm = props => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);

    const { currentUser } = useContext(AuthContext);
    const { data, dispatch } = useContext(ChatContext);

    /*
    So, figure out how the react/firebase app did this.
    */
    const handleSelect = (u) => {
        dispatch({ type: "CHANGE_USER", payload: u });
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const displayName = e.target[0].value;
        const emailString = e.target[0].value;
        const password = e.target[0].value;
        let loggedInUser = ''

        //We won't upload a file - instead we'll use a default avatar
        // const file = e.target[3].files[0];
        let combinedId = '';

        try {

            // get the support user
            const supportUserRes = await getDoc(doc(db, "users", supportUserID));
            console.log('Support user: ', supportUserRes.data())
            // find the user

            const q = query(collection(db, "users"), where("email", "==", emailString));

            const querySnapshot = await getDocs(q);

            props.setUser(querySnapshot.docs[0].data());
            console.log('querySnapshot ', querySnapshot.docs[0].data().uid);

            // let numberOfResults = querySnapshot.size;

            //if the user does not exist, create the user 
            if (querySnapshot.size == 0) {
                console.log(`User ${emailString} does not exist. Attempting to create new user`);
                // gonna assume the user is new, so create them.
                // not the best way to do this, but it will do for now.
                // just be sure to add some validation to safeguard against injection attacks

                //Create user
                const newUserRes = await createUserWithEmailAndPassword(auth, emailString, password);

                try {

                    //Update profile
                    await updateProfile(newUserRes.user, {
                        displayName,
                        photoURL: defaultAvatarURL,
                    });
                    //create user on firestore
                    await setDoc(doc(db, "users", newUserRes.user.uid), {
                        uid: newUserRes.user.uid,
                        displayName,
                        email,
                        photoURL: defaultAvatarURL,
                    });

                    props.setUser({
                        uid: newUserRes.user.uid,
                        displayName,
                        email,
                        photoURL: defaultAvatarURL,
                    });

                    //create empty user chats on firestore
                    await setDoc(doc(db, "userChats", newUserRes.user.uid), {});

                    combinedId = newUserRes.user.uid > supportUserID
                        ? newUserRes.user.uid + supportUserID
                        : supportUserID + newUserRes.user.uid;

                    // add a support chat to the new user chat
                    const supportChatRes = await getDoc(doc(db, "chats", combinedId));

                    if (!supportChatRes.exists()) {
                        //create a chat in chats collection
                        await setDoc(doc(db, "chats", combinedId), { messages: [] });

                        //create user chats
                        // one from current user to support
                        await updateDoc(doc(db, "userChats", newUserRes.user.uid), {
                            [combinedId + ".userInfo"]: {
                                uid: supportUserID,
                                displayName: supportUserRes.data().displayName,
                                photoURL: supportUserRes.data().photoURL,
                            },
                            [combinedId + ".date"]: serverTimestamp(),
                        });

                        // another from  support to current user
                        await updateDoc(doc(db, "userChats", supportUserID), {
                            [combinedId + ".userInfo"]: {
                                uid: newUserRes.user.uid,
                                displayName: newUserRes.user.displayName,
                                photoURL: newUserRes.user.photoURL,
                            },
                            [combinedId + ".date"]: serverTimestamp(),
                        });
                    }

                } catch (err) {
                    console.log(err);
                    setErr(true);
                    setLoading(false);
                }
            }
            else {
                console.log(`User ${email} exists. Attempting signin with user`);
                const signinRes = await signInWithEmailAndPassword(auth, email, password);
                props.setUser(signinRes.user);


                combinedId = signinRes.user.uid > supportUserID
                    ? signinRes.user.uid + supportUserID
                    : supportUserID + signinRes.user.uid;

                console.log('currentUser: ', signinRes.user);
                console.log('combinedID: ', combinedId);

                let signinUserChatsRes = await getDoc(doc(db, "userChats", signinRes.user.uid));

                if (!signinUserChatsRes.exists()) {
                    console.log('There are no user chats for', email, ', creating new chat thread');


                }
                else {
                    console.log('User chats exist for', email);

                    console.log('userChats', signinUserChatsRes.data());
                    // console.log(signinUserChatsRes.data()[combinedId]);

                    props.setChat(signinUserChatsRes.data()[combinedId]);
                    handleSelect(signinUserChatsRes.data()[combinedId].userInfo);

                    if (signinUserChatsRes.data()[combinedId] === undefined) {
                        console.log('no chat session exists with support. add one.');

                        //create a chat in chats collection
                        await setDoc(doc(db, "chats", combinedId), { messages: [] });

                        //create user chats
                        // one from current user to support
                        await updateDoc(doc(db, "userChats", signinRes.user.uid), {
                            [combinedId + ".userInfo"]: {
                                uid: supportUserID,
                                displayName: supportUserRes.data().displayName,
                                photoURL: supportUserRes.data().photoURL,
                            },
                            [combinedId + ".date"]: serverTimestamp(),
                        });

                        // another from  support to current user
                        await updateDoc(doc(db, "userChats", supportUserID), {
                            [combinedId + ".userInfo"]: {
                                uid: signinRes.user.uid,
                                displayName: signinRes.user.displayName,
                                photoURL: signinRes.user.photoURL,
                            },
                            [combinedId + ".date"]: serverTimestamp(),
                        });

                        signinUserChatsRes = await getDoc(doc(db, "userChats", signinRes.user.uid));

                        if (signinUserChatsRes.exists()) {
                            props.setChat(signinUserChatsRes.data()[combinedId]);
                            handleSelect(signinUserChatsRes.data()[combinedId].userInfo);

                            console.log('ChatContext data: ', data);
                        }

                    }

                    // const chatRes = await getDoc(doc(db, "chats", combinedId));
                    // if (!chatRes.exists()) {
                    //     console.log('There is no chat session for support. combined id: ', combinedId);

                    //     //create a chat in chats collection
                    //     await setDoc(doc(db, "chats", combinedId), { messages: [] });

                    //     const newChatRes = await getDoc(doc(db, "chats", combinedId));

                    //     if (newChatRes.exists()) {
                    //         props.setChat(newChatRes.data());
                    //         setLoading(false);
                    //     }

                    // }
                    // else {
                    //     console.log('A chat session for support is available: ', chatRes.data());

                    //     props.setChat(chatRes.data());
                    //     setLoading(false);
                    // }



                    // props.setChat && props.setChat(chat)
                }
            }

            // const {currentUser} = useContext(AuthContext)

        } catch (err) {
            console.log(err);
            setErr(true);
            setLoading(false);
        }
        /*
        try {
            
            //Create user
            const res = await createUserWithEmailAndPassword(auth, email, password);

            //Create a unique image name
            const date = new Date().getTime();
            const storageRef = ref(storage, `${displayName + date}`);

            try {
                //Update profile
                await updateProfile(res.user, {
                    displayName,
                    photoURL: defaultAvatarURL,
                });
                //create user on firestore
                await setDoc(doc(db, "users", res.user.uid), {
                    uid: res.user.uid,
                    displayName,
                    email,
                    photoURL: defaultAvatarURL,
                });

                //create empty user chats on firestore
                await setDoc(doc(db, "userChats", res.user.uid), {});
                navigate("/");
            } catch (err) {
                console.log(err);
                setErr(true);
                setLoading(false);
            }

        } catch (err) {
            setErr(true);
            setLoading(false);
        }
        */

    };

    return (
        <div
            style={{
                ...styles.emailFormWindow,
                ...{
                    height: props.visible ? '100%' : '0px',
                    opacity: props.visible ? '1' : '0'
                }
            }}
        >
            <div style={{ height: '0px' }}>
                <div style={styles.stripe} />
            </div>

            <div
                className='transition-5'
                style={{
                    ...styles.loadingDiv,
                    ...{
                        zIndex: loading ? '10' : '-1',
                        opacity: loading ? '0.33' : '0',
                    }
                }}
            />
            <LoadingOutlined
                className='transition-5'
                style={{
                    ...styles.loadingIcon,
                    ...{
                        zIndex: loading ? '10' : '-1',
                        opacity: loading ? '1' : '0',
                        fontSize: '82px',
                        top: 'calc(50% - 41px)',
                        left: 'calc(50% - 41px)',
                    }
                }}
            />

            <div style={{ position: 'absolute', height: '100%', width: '100%', textAlign: 'center' }}>
                <Avatar
                    style={{
                        position: 'relative',
                        left: 'calc(50% - 44px)',
                        top: '10%',
                    }}
                />

                <div style={styles.topText}>
                    Welcome to my <br /> support 👋
                </div>

                <form
                    onSubmit={e => handleSubmit(e)}
                    style={{ position: 'relative', width: '100%', top: '19.75%' }}
                >
                    <input
                        placeholder='Your Email'
                        type="email"
                        onChange={e => setEmail(e.target.value)}
                        style={styles.emailInput}
                    />
                </form>

                <div style={styles.bottomText}>
                    Enter your email <br /> to get started.
                </div>
            </div>
        </div>
    )
}

export default EmailForm;