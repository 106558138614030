import { createContext, useEffect, useState } from "react";
import { collection, query, where, doc, getDoc, getDocs, orderBy, limit } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";




export const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);


export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [role, setRole] = useState({});
  const [backgroundId, setBackgroundId] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryVideoBackground = async () => {
      console.log(`getting video background for ${new Date().toISOString().split('T')[0]}`);

      // query db for video url by date
      const docRef = doc(db, "scheduledvideobackground", new Date().toISOString().split('T')[0]);
      let videoUrl = '';
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        videoUrl = docSnap.data().url;

        // set video background ID
        setBackgroundId(videoUrl.split("v=")[1].split("&")[0]);
        setLoading(false);

      } else {
        console.log("No such document!");
        // get scheduled video background collection
    

       

        // query db for video url by date
        const q = query(collection(db, 'scheduledvideobackground'), where('dateActive', '<=', new Date()), orderBy('dateActive', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);
        console.log(`querying for background video dated ${new Date().toISOString().split('T')[0]}...`);
        querySnapshot.forEach((doc) => {
          // console.log(doc.data().url);
          videoUrl = doc.data().url;
        });

        // set video background ID
        if (videoUrl.length > 0) {
          setBackgroundId(videoUrl.split("v=")[1].split("&")[0]);
          
        } else {
          console.log('No video of the day found for today. Using default video.');
          setBackgroundId("");
        }
        setLoading(false);
      }
    };

    const unsub = onAuthStateChanged(auth, (user) => {
      let tmp = queryVideoBackground();

      console.log(`Background ID is : ${backgroundId}`);
      if (user) {
        const userRole = fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_API}/users/${user.uid}`).then((response) => response.json()).then((data) => {
          setRole(data.user.role);
          setLoading(false);

          //console.log(`role is now ${JSON.stringify(data.user.role)}`);
        });
        // console.log(`user is now ${JSON.stringify(user)}`);

      }
      setCurrentUser(user);
      console.log(`current user is now ${JSON.stringify(user)}`);
      // console.log(`x is now ${JSON.stringify(x)}`);
    });



    return () => {
      queryVideoBackground();
      unsub();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, backgroundId, role, setCurrentUser }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};


