import React, { useState, useContext } from "react";
import Add from "../img/addAvatar.png";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db, storage, supportUserID } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";

const Register = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const { currentUser } = useContext(AuthContext);
  const { data, dispatch } = useContext(ChatContext);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const displayName = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    const file = e.target[3].files[0];

    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);
      // get the support user
      const supportUserRes = await getDoc(doc(db, "users", supportUserID));
      //Create a unique image name
      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`);
      let combinedId = '';
      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          try {
            //Update profile
            await updateProfile(res.user, {
              displayName,
              photoURL: downloadURL,
            });
            //create user on firestore
            await setDoc(doc(db, "users", res.user.uid), {
              uid: res.user.uid,
              displayName,
              email,
              photoURL: downloadURL,
            });

            //create empty user chats on firestore
            await setDoc(doc(db, "userChats", res.user.uid), {});

            combinedId = supportUserID + res.user.uid;
            const temp3 = await updateDoc(doc(db, "userChats", supportUserID), {
              [combinedId + ".userInfo"]: {
                uid: res.user.uid,
                displayName: displayName,
                photoURL: downloadURL,
         
              },
              [combinedId + ".date"]: serverTimestamp(),
              [combinedId + ".extra"]: "zzz",
            });
            // add a support chat to the new user chat
            const supportChatRes = await getDoc(doc(db, "chats", combinedId));
          
            if (!supportChatRes.exists()) {
              //create a chat in chats collection
              const temp1 = await setDoc(doc(db, "chats", combinedId), { messages: [] });
              console.log("temp1");
              //create user chats
              // one from current user to support
              const temp2 = await updateDoc(doc(db, "userChats", res.user.uid), {
                [combinedId + ".userInfo"]: {
                  uid: supportUserID,
                  displayName: supportUserRes.data().displayName,
                  photoURL: supportUserRes.data().photoURL,
                 
                },
                [combinedId + ".date"]: serverTimestamp(),
                [combinedId + ".extra"]: "yyy",
              });
              console.log("temp 2");
              // another from  support to current user
              const temp3 = await updateDoc(doc(db, "userChats", supportUserID), {
                [combinedId + ".userInfo"]: {
                  uid: res.user.uid,
                  displayName: displayName,
                  photoURL: downloadURL,
           
                },
                [combinedId + ".date"]: serverTimestamp(),
                [combinedId + ".extra"]: "xxx",
              });
              console.log("temp 3");
              dispatch({ type: "CHANGE_USER", payload:  {
                uid: res.user.uid,
                displayName: displayName,
                photoURL: downloadURL,
         
              } });
            }

            navigate("/");
          } catch (err) {
            console.log(err);
            setErr(true);
            setLoading(false);
          }
        });
      });
    } catch (err) {
      console.log(err);
      setErr(true);
      setLoading(false);
    }
  };

  return (
    <div className="loginpage formContainer">
      <div className="login-div">
        <div className="formWrapper">
          <span className="logo">Lama Support Chat</span>
          <span className="title">Register</span>
          <form onSubmit={handleSubmit}>
            <input required type="text" placeholder="display name" />
            <input required type="email" placeholder="email" />
            <input required type="password" placeholder="password" />
            <input required style={{ display: "none" }} type="file" id="file" />
            <label htmlFor="file">
              <img src={Add} alt="" />
              <span>Add an avatar</span>
            </label>
            <button disabled={loading}>Sign up</button>
            {loading && "Uploading and compressing the image please wait..."}
            {err && <span>Something went wrong</span>}
          </form>
          <p>
            You do have an account? <Link to="/register">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
