import React, { useEffect, useState } from "react";
import { collection, query, where, doc, getDoc, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from '../firebase';
import Modal from 'react-bootstrap/Modal';
import { TikTok } from 'react-tiktok';
import YouTube from 'react-youtube';
import '../mobile-styles.css';

const VideoOfTheDayIcon = () => {
    const [showModal, setShow] = useState(false);
    const [videoOfTheDayURL, setVideoOfTheDayURL] = useState('https://www.tiktok.com/@frogstodogs/video/7102119778537655598?is_from_webapp=1&sender_device=pc&web_id=7230523340547606059');
    const [hovered, setHovered] = useState(false)
    const [youtubeVideoId, setyoutubeVideoId] = useState([]);
    const opts = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getVideoOfTheDayURL = async () => {
        console.log(`getting video for ${new Date().toISOString().split('T')[0]}`);

        // query db for video url by date
        const docRef = doc(db, "scheduledvideos", new Date().toISOString().split('T')[0]);
        let videoUrl = '';
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            videoUrl = docSnap.data().url;

            // set video url
            setVideoOfTheDayURL(videoUrl);
            console.log(videoUrl)
            if (videoUrl.includes("youtube")) {
                setyoutubeVideoId(videoUrl.split("v=")[1].split("&")[0]);
            }
        } else {

            // query db for video url by date
            const q = query(collection(db, "scheduledvideos"), where("id", ">=", new Date().toISOString().split('T')[0]), orderBy('id'), limit(1));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                videoUrl = doc.data().url;
            });

            // set video url
            if (videoUrl.length > 0) {
                setVideoOfTheDayURL(videoUrl);
                if (videoUrl.includes("youtube")) {
                    setyoutubeVideoId(videoUrl.split("v=")[1].split("&")[0]);
                }
            } else {
                console.log('No video of the day found for today. Using default video.');
                setVideoOfTheDayURL('https://www.tiktok.com/@frogstodogs/video/7102119778537655598?is_from_webapp=1&sender_device=pc&web_id=7230523340547606059');
                if (videoUrl.includes("youtube")) {
                    setyoutubeVideoId(videoUrl.split("v=")[1].split("&")[0]);
                }
            }
        }
    };


    useEffect(() => {
        getVideoOfTheDayURL();
    }, []);

    return (
        <div
            className="video-of-the-day-icon"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                backgroundColor: 'white',
                width: '84px',
                height: '84px',
                position: 'fixed',
                top: '100px',
                right: '24px',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                borderRadius: '50%',
                ...{ border: hovered ? '1px solid #f9f0ff' : '4px solid #7a39e0' }
            }}>
            <div className='transition-3' onClick={handleShow}>
                Video of the day
            </div>
            <Modal show={showModal} onHide={handleClose} className="modal-style">
                <Modal.Header closeButton>
                    <Modal.Title>Video Of The Day</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {videoOfTheDayURL.includes("tiktok") ?
                        <TikTok url={videoOfTheDayURL} />
                        :
                        <><YouTube videoId={youtubeVideoId} iframeClassName="youtubeIframe" opts={opts} />
                        <></></>
                      
                    }

                </Modal.Body>
            </Modal>
        </div>

    )
}

export default VideoOfTheDayIcon;