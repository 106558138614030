import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import '../style-components.css';

const Roles = () => {
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);

    // change display name and email address when user is selected
    const handleChange = async (e) => {
        const userid = e.target.value;
        const { data } = await axios.get(`${process.env.REACT_APP_CLOUD_FUNCTION_API}/users/${userid}`);
        // console.log(data);
        // parse user object
        //const result = JSON.parse(data.user);


        document.getElementById('displayname').value = data.user.displayName;
        document.getElementById('email').value = data.user.email;
    }


    // use useEffect to populate select with users from http request
    useEffect(() => {

        console.log(`cloud function api: ${process.env.REACT_APP_CLOUD_FUNCTION_API}`);
        
        const getUsers = async () => {
            const { data } = await axios.get(`${process.env.REACT_APP_CLOUD_FUNCTION_API}/users`);
            // console.log(data);
            const results = [];
            // push properties from data into results array
            data.users.forEach(user => {
                //parse user object
                user = JSON.parse(user);
                // console.log(user.uid);
                results.push({ value: user.uid, key: user.email });
            });



            // Update the options state
            setOptions([
                { key: 'Select a user', value: '' },
                ...results
            ]);
        }

        //trigger getUsers function
        getUsers();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // get user select object
        const select = document.getElementById('user');
        // get selected value
        const selectedValue = select.options[select.selectedIndex].value;
        // get role select object
        const roleSelect = document.getElementById('role');
        // get selected value
        const roleValue = roleSelect.options[roleSelect.selectedIndex].value;
        // create body object
        const body = {
            role: roleValue
        };

         

        // call the http request to add user role
        const { data } = await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTION_API}/users/${selectedValue}/roles`, body)
            .then((response) => {
                // console.log(response);
                alert('Role updated successfully!');
            })
            .catch(error => {
                setErr(true);
                console.error('There was an error!', error);
            });

    };


    return (
        <div className="roles">
            <div className="services-div" id="noScroll">
                <h1>Roles</h1>
                <Form onSubmit={handleSubmit} className="set-roles-mobile">
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2} className="text-center">
                            Select user
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Select id="user" onChange={handleChange}>
                                {options.map((option) => {
                                    return (
                                        <option key={option.value} value={option.value}>
                                            {option.key}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-center">
                        <Form.Label column sm={2}>
                            Display name
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control id="displayname" placeholder="Disabled input" disabled />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-center">
                        <Form.Label column sm={2}>
                            Email address
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control type="email" id="email" placeholder="Disabled input" disabled />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-center">
                        <Form.Label column sm={2}>
                            Role
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Select name="role" id="role">
                                <option value="Select a role">Select a role</option>
                                <option value="Friend">Friend</option>
                                <option value="Family">Family</option>
                                <option value="Manager">Manager</option>
                                <option value="Admin">Admin</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-center">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button type="submit">Update Role</Button>
                            <input type="hidden" name="uid" value="" />
                            {err && <span>Something went wrong</span>}
                        </Col>
                    </Form.Group>

                </Form>

            </div>
        </div>

    );
}

export default Roles;