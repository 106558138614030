import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from "react";
import { AuthContext } from '../context/AuthContext'

const PrivateRoutes = () => {
    const { currentUser, backgroundId, role } = useContext(AuthContext);
    //console.log(`user in private route: ${JSON.stringify(currentUser)}`);
    let auth = { 'token':currentUser && (role === 'Admin' || role === 'Manager' )};
    console.log(`auth: ${auth.token}`);
    console.log(`role in private route: ${role}`);
    return (
        auth.token ? <Outlet /> : <Navigate to='/login' />
    )
}

export default PrivateRoutes;