import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import {  getFunctions, httpsCallable,  connectFunctionsEmulator} from "firebase/functions";



// import {admin} from 'firebase-admin';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID

};

console.log(process.env.REACT_APP_DEFAULTAVATARURL);

const defaultAvatarURL = process.env.REACT_APP_DEFAULTAVATARURL;

const supportUserID = process.env.REACT_APP_SUPPORTUSERID;

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
export const functions = getFunctions();
export const callable = httpsCallable;

export { defaultAvatarURL, supportUserID };
