import * as React from 'react';
import Img1 from "../img/manWithDogs.jpeg"; 
import Img2 from "../img/zsoltWithDog.jpeg"; 

export default function AboutUs() {
  return (
    <div className="booking">
      <div className="services-div"  style={{padding:'2%',height: '39rem',overflowY:'scroll'}}>
        <h1>About Us</h1>
        <div style={{display:'flex',justifyContent:'space-evenly', width:'100%', paddingBottom: '2rem',paddingTop:'2rem'}}>
        <img src={Img1} alt=""style={{height:'11rem'}} />
        <img src={Img2} alt="" style={{height:'11rem'}}/>
        </div>
        <p>Welcome to Trainer On Retainer, where training is customized to meet the unique needs of both dog and human. Think of us as a life coach for you and your dog. 
</p>
            <p>Unlike trainers who back you into a corner with limited time frames or tactics you don't love, Trainer on Retainer offers a customized plan for you and your dog with a trainer known for 30+ years of experience handling difficult dogs. 
</p>
            <p>Offered as a monthly membership, you'll have the flexibility to shape the plan according to your schedule. The choice of when you and your dog are ready to exit the program is yours. 
</p>
            <p>If you're a human struggling to get along with your dog, sign-up for a free consultation. Let's see if Trainer on Retainer is right for you. 
</p>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
           
            
          <a
            style={{ float: 'none'}}
            target="_blank"
            href="https://booking.setmore.com/scheduleappointment/d8143eea-fe7d-4c97-ae15-591260e588a5"
          >
            <img
              border="none"
              src="https://storage.googleapis.com/full-assets/setmore/images/1.0/Settings/book-now-blue.png"
              alt="Book an appointment with Dog Trainer Retainer using Setmore"
            />
          </a>
        </div>
      </div>
    </div>
  );
}