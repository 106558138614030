import {
  createContext,
  useContext,
  useReducer,
} from "react";
import { supportUserID } from "../firebase";
import { AuthContext } from "./AuthContext";

export const ChatContext = createContext();

export const ChatContextProvider = ({ children }) => {
  const { currentUser,role } = useContext(AuthContext);
  const INITIAL_STATE = {
    chatId: "null",
    user: {},
  };

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user:
          role === "Admin" || role === "Manager"
          ? action.payload
          : currentUser,
          chatId:
          role === "Admin" || role === "Manager"
              ? supportUserID + action.payload.uid
              : supportUserID + currentUser.uid,

        };
        case "LOGOUT_USER":
        return {
          user: {},
          chatId: "null",
        };


      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ data:state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};
